.btn-navbar {
  .dropdown-toggle {
    white-space: nowrap;
    background: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    color: #9a9a9a !important;
  }
  .dropdown-toggle::after {
    display: none !important;
    @media only screen and (max-width: 600px) {
      display: inline-block !important;
    }
  }
  .active {
    .dropdown-toggle {
      white-space: nowrap;
      background: #c99843 !important;
      color: white !important;
    }
  }
  .dropdown-menu {
    background: #c99843 !important;
    border-radius: 0px !important;
    height: 600px;
    overflow: auto;
    a:hover {
      background-color: #80001f;
    }
  }
  .dropdown-item {
    color: white !important;
  }
}

.modal-90w {
  width: 99% !important;
  max-width: none !important;
  .modal-content {
    height: 98vh !important;
  }
}
.navdekstop-container {
  @media only screen and (max-width: 911px) {
    display: none !important;
  }
}
.nav-mobile {
  @media only screen and (min-width: 910px) {
    display: none !important;
  }
  .icon-white {
    color: white !important;
    font-size: 1.5em !important;
  }
  .icon-black {
    color: black !important;
    font-size: 1.5em !important;
  }
  .icon-transparent {
    color: transparent !important;
    font-size: 1.5em !important;
  }
  .height-embed {
    height: 85%;
  }
  .navbar-padding {
    padding: 0px;
  }
}

.mobile-line {
  margin-bottom: -30px;
}

.desktop-dropdown-menu {
  .dropdown-menu-end {
    inset: -40px -190px auto auto !important;
    width: 210px;
  }

  div[aria-labelledby='nested-dropdown-toggle-2'] {
    transform: translate3d(29px, 40px, 0px) !important;
  }

  div[aria-labelledby='nested-dropdown-toggle-3'] {
    width: 220px !important;
    margin-right: -60px;
  }
  div[aria-labelledby='nested-dropdown-toggle-4'] {
    width: 220px !important;
    margin-right: -68px;
  }
  .dropdown-item {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    color: #cda257 !important;
  }

  .custom-toggle-dropdown {
    width: 165px !important;
    background: transparent !important;
    border: transparent !important;
    text-align: left !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    color: #cda257 !important;
  }

  .custom-toggle-dropdown1 {
    width: 100% !important;
    background: transparent !important;
    color: black !important;
    border: transparent !important;
    text-align: left !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  .custom-toggle-dropdown12 {
    width: 60% !important;
    background: transparent !important;
    color: black !important;
    border: transparent !important;
    text-align: left !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  .custom-toggle-dropdown2 {
    width: 185px !important;
    background: transparent !important;
    border: transparent !important;
    text-align: left !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    color: #cda257 !important;
  }

  .sub-dropdown-menu {
    .btn.dropdown-toggle::after {
      display: none;
    }
  }

  .all-fnc {
    padding-left: 30px !important;
  }

  .active {
    background: rgba(205, 162, 87, 0.1);
    border-radius: 5px;
  }

  .custom-toggle-dropdown1::before {
    content: '';
  }

  .custom-toggle-dropdown1:empty::after {
    display: none !important;
  }
}

.mobile-dropdown-menu {
  .dropdown-menu-end {
    inset: 40px 43px auto auto !important;
  }

  div[aria-labelledby='nested-dropdown-toggle-2'] {
    transform: translate3d(29px, 40px, 0px) !important;
  }

   div[aria-labelledby='dropdown-split-basic'] {
    width: 310% !important;
    margin-right: -60px;
  }

  div[aria-labelledby='nested-dropdown-toggle-3'] {
    width: 265px !important;
    margin-right: -60px;
  }
   div[aria-labelledby='nested-dropdown-toggle-4'] {
    width: 265px !important;
    margin-right: -64px;
  }
  .dropdown-item {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    color: #cda257 !important;
  }

  .custom-toggle-dropdown {
    width: 215px !important;
    background: transparent !important;
    border: transparent !important;
    text-align: left !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    color: #cda257 !important;
  }

  .custom-toggle-dropdown1 {
    width: 100% !important;
    background: transparent !important;
    color: black !important;
    border: transparent !important;
    text-align: left !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  .custom-toggle-dropdown12 {
    width: 70% !important;
    background: transparent !important;
    color: black !important;
    border: transparent !important;
    text-align: left !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  .custom-toggle-dropdown2 {
    width: 185px !important;
    background: transparent !important;
    border: transparent !important;
    text-align: left !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    color: #cda257 !important;
  }

  .sub-dropdown-menu {
    .btn.dropdown-toggle::after {
      display: none;
    }
  }

  .all-fnc {
    padding-left: 30px !important;
  }

  .active {
    background: rgba(205, 162, 87, 0.1);
    border-radius: 5px;
  }

  .custom-toggle-dropdown1::before {
    content: '';
  }

  .custom-toggle-dropdown1:empty::after {
    display: none !important;
  }
}

.off-canvas2 {
  flex-grow: 0 !important;
}

#lang-dropdown:hover {
  background-color: #c99843;
  border: none !important
}
.custom-dropdown-button {
  min-width: 120px !important;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none !important;
    box-shadow: none !important;
}